<template>
  <div
    class="absolute top-14 left-16 z-99 px-4 py-2 bg-white border flex flex-col w-full ml-4 filters-container"
    style="top: 155px; right: 0"
  >
    <div class="flex justify-between w-full">
      <span class="filter_title">{{ $t('monitoring.activeFilters') }}</span>
      <i @click="closeModal" class="el-icon-close pointer close-btn" />
    </div>
    <div
      class="flex mt-2 justify-between mr-2 w-full"
      v-for="(select, index) in currentFilter"
      :key="index"
    >
      <div class="flex justify-between w-full">
        <el-select
          size="small"
          style="height: 32px"
          v-model="select.field"
          @change="handleFilterFieldChange(select, index)"
          :placeholder="$t('monitoring.choose')"
          class="filter-select"
        >
          <el-option
            v-for="(item, key) in select.options"
            :key="key"
            :label="item.key"
            :value="item.value"
          ></el-option>
        </el-select>
        <div
          class="w-full ml-2"
          v-if="select.field === 'driver'"
          style="flex-grow: 2"
        >
          <el-select
            class="w-full filter-select"
            size="small"
            v-model="select.values"
            multiple="multiple"
          >
            <el-option
              v-for="(driver, key) in drivers"
              :key="key"
              :label="driver.name"
              :value="driver.id"
            >
            </el-option>
          </el-select>
        </div>
        <div
          class="w-full ml-2"
          v-else-if="select.field === 'groups'"
          style="flex-grow: 2"
        >
          <el-select
            class="w-full filter-select"
            size="small"
            v-model="select.values"
            multiple="multiple"
          >
            <el-option
              v-for="(unitGroup, key) in unitsGroups"
              :key="key"
              :label="unitGroup.name"
              :value="unitGroup.id"
            >
            </el-option>
          </el-select>
        </div>
        <div
          v-else-if="select.field === 'sensors.type.key'"
          class="w-full ml-2"
          style="flex-grow: 2"
        >
          <el-select
            class="w-full filter-select"
            size="small"
            v-model="select.values"
            multiple="multiple"
          >
            <el-option
              v-for="(item, key) in sensortype"
              :key="key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </div>
        <el-input
          class="ml-2 text-area"
          v-else
          style="flex-grow: 2"
          type="textarea"
          :autosize="{ minRows: 1, maxRows: 2 }"
          :placeholder="$t('monitoring.searchHolder')"
          v-model="select.values"
        ></el-input>
      </div>
      <span class="ml-4 pt-1 cursor-pointer" @click.stop="remove(index)">
        <svg
          width="12"
          height="16"
          viewBox="0 0 12 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3 1H0V3H12V1H9L8 0H4L3 1ZM11 4H1V14C1 14.5304 1.21071 15.0391 1.58579 15.4142C1.96086 15.7893 2.46957 16 3 16H9C9.53043 16 10.0391 15.7893 10.4142 15.4142C10.7893 15.0391 11 14.5304 11 14V4Z"
            fill="#446CA7"
          />
        </svg>
      </span>
    </div>
    <p class="filter_title error_text" v-if="isError">
      {{ $t('monitoring.filter.fieldsEmpty') }}
    </p>

    <div class="flex justify-between">
      <el-tooltip
        v-if="isManyFilters"
        :open-delay="700"
        effect="dark"
        :content="$t('monitoring.filterMax')"
        placement="bottom"
        :offset="2"
        :visible-arrow="false"
      >
        <button
          type="button"
          :class="['focus_outline-none', 'filter_buttonDisabled', 'my-4']"
        >
          <span style="pointer-events: none">{{
            $t('monitoring.addFilter')
          }}</span>
        </button>
      </el-tooltip>
      <button
        v-else
        type="button"
        :class="[
          'focus_outline-none',
          'filter_addFilter',
          'cursor-pointer',
          'my-4'
        ]"
        @click="addSelect"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8" cy="8" r="7.5" stroke="#3C5E8F" />
          <path
            d="M7 5C7 4.44772 7.44772 4 8 4C8.55228 4 9 4.44772 9 5V11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11V5Z"
            fill="#3C5E8F"
          />
          <path
            d="M11 7C11.5523 7 12 7.44772 12 8C12 8.55228 11.5523 9 11 9L5 9C4.44772 9 4 8.55228 4 8C4 7.44772 4.44772 7 5 7L11 7Z"
            fill="#3C5E8F"
          />
        </svg>

        {{ $t('monitoring.addFilter') }}
      </button>

      <span
        class="filter_addFilter accept-btn cursor-pointer my-4"
        @click="search"
        >{{ $t('monitoring.accept') }}</span
      >
    </div>
  </div>
</template>
<script>
import { monitoringFilterApi } from '@/api'
import { getwsUserId } from '@/api/ws.js'
import websocketHandler from '@/mixins/websocketHandler.js'
import { mapState } from 'vuex'

export default {
  mixins: [websocketHandler],
  props: ['currentFilter'],
  data() {
    return {
      filterRow: [
        {
          values: ''
        }
      ],
      isManyFilters: false,
      isError: false,
      errorNumber: '',
      filtersList: [
        { key: this.$t('monitoring.filter.name'), value: 'name' },
        { key: this.$t('monitoring.filter.imei'), value: 'imei' },
        { key: this.$t('monitoring.filter.phoneNumber'), value: 'phonenumber' },
        { key: this.$t('monitoring.search.custom_fields'), value: 'custom_fields' },
        { key: this.$t('monitoring.filter.sensorName'), value: 'sensors.name' },
        { key: this.$t('monitoring.filter.sensorType'), value: 'sensors.type.key' },
        { key: this.$t('monitoring.filter.sensorParam'), value: 'sensors.param' },
        { key: this.$t('monitoring.filter.event'), value: 'events' },
        { key: this.$t('monitoring.filter.driver'), value: 'driver' },
        { key: this.$t('monitoring.search.groups'), value: 'groups' }
      ]
    }
  },
  computed: {
    ...mapState('users', {
      drivers: (state) => {
        return state.users.filter((user) => user.is_driver)
      }
    }),
    ...mapState({
      sensortype: (state) => state.dictionary.sensor_type,
      unitsGroups: (state) => state.units.unitsGroups,
      me: (state) => state.login.me
    })
  },

  watch: {
    currentFilter() {
      if (this.currentFilter.length === 0) {
        const id = getwsUserId()
        monitoringFilterApi.monitoring_filter(id, { conditions: [] })
      }

      this.isManyFilters = this.currentFilter.length >= 30
    }
  },

  methods: {
    closeModal() {
      this.$emit('close', [])
    },

    search() {
      if (!this.currentFilter.length) {
        this.$emit('close', [])
        return
      }

      const result = JSON.parse(JSON.stringify(this.currentFilter))

      this.isError = false

      result.forEach((el, index) => {
        delete el.options

        if (el.values === '') {
          this.isError = true
          this.errorNumber = index + 1
          el.isValuesMissing = true
        } else if (!el.field) {
          this.isError = true
          this.errorNumber = index + 1
        }

        if (typeof el.values === 'string') {
          el.values = el.values.split(',')
        }
      })

      if (!this.isError) {
        const data = []
        const id = getwsUserId()

        result.forEach((el) => {
          if (el.field === 'sensors.type.key') {
            const formData = {
              conditions: [
                {
                  field: 'sensors.type.key',
                  value: el.values[0]
                }
              ]
            }
            data.push(formData)
          } else {
            data.push(el)
          }
        })

        if (data.length) {
          monitoringFilterApi.monitoring_filter(id, { conditions: result })
        }

        this.$emit('close', this.currentFilter)
      }
    },

    handleFilterFieldChange(item, index) {
      this.currentFilter[index].values = ''
    },

    addSelect() {
      console.log(this.$t('monitoring.addFilter'));

      if (this.currentFilter.length >= 30) {
        this.isManyFilters = true
      } else {
        this.currentFilter.push({
          options: this.filtersList,
          values: ''
        })
        this.isManyFilters = false
      }
    },

    remove(index) {
      if (this.currentFilter.length) {
        this.currentFilter.splice(index, 1)
        this.$emit('remove-all-selection')
      } else {
        const id = getwsUserId()

        monitoringFilterApi.monitoring_filter(id, { conditions: [] })
        this.$nextTick(() => this.$store.commit('units/RESET_FILTRED_UNITS'))
        localStorage.setItem(
          `filtredUnits_${this.me.active_company.id}`,
          JSON.stringify([])
        )
      }
    }
  },

  mounted() {
    this.isManyFilters = this.currentFilter.length >= 30
  }
}
</script>

<style>
.filter-select input,
.text-area textarea {
  background: #f6f7fa;
  border: 1px solid #cdd4dd;
  border-radius: 4px;
}
</style>
<style scoped>
.filters-container {
  z-index: 32;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.error_text {
  color: #f56c6c !important;
  opacity: 1 !important;
}

.filter_title {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #828d9b;
  margin-bottom: 12px;
}
.filter_addFilter,
.filter_buttonDisabled {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
}
.filter_addFilter {
  color: #3c5e8f;
}
.accept-btn {
  color: white;
  background-color: #3c5e8f;
  padding: 4px 16px 5px 16px;
  border-radius: 8px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
}
.close-btn {
  font-size: 16px;
  color: #446ca7;
  font-weight: 600;
  margin-top: 2px;
  height: 16px;
}
.filter_buttonDisabled {
  color: #96999c;
  cursor: not-allowed;
}
</style>
